import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { dateAndTimeToReadString } from "../utils/format"
import Line from "../components/line"
import Subscribe from "../components/subscribe"

function renderInterview(youtube, spotify, title) {
  return (
    <div>
      <iframe
        width="100%"
        height="315"
        src={`https://www.youtube.com/embed/${youtube}`}
        title={`YouTube - ${title}`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src={`https://open.spotify.com/embed-podcast/episode/${spotify}`}
        title={`Spotify - ${title}`}
        width="100%"
        height="232"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    </div>
  )
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const { is_interview, youtube_link, spotify_link } = post.frontmatter

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          ogType="article"
          draft={post.frontmatter.draft}
        />
        <div style={{ marginBottom: "40px" }}>
          <Line />

          <h1
            style={{
              marginTop: "10px",
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <small
            style={{
              display: `block`,
              margin: 0,
              marginBottom: "5px",
            }}
          >
            {dateAndTimeToReadString(post)}
          </small>
          <Line />
        </div>
        {is_interview
          ? renderInterview(youtube_link, spotify_link, post.frontmatter.title)
          : null}
        <article dangerouslySetInnerHTML={{ __html: post.html }} />

        <Subscribe />

        <Bio />

        <ul
          style={{
            listStyle: `none`,
            textAlign: "center",
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "YYYY.MM.DD")
        description
        draft
        is_interview
        youtube_link
        spotify_link
      }
    }
  }
`
